<template>
<div class="el_main">
   <div>
        <div class="xuanxiang">
            <el-row></el-row>
            <el-row :gutter="20">
            <el-col :span="6" :offset="1"><div class="grid-content bg-purple">
                <el-date-picker
                v-model="range_time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份"
                @change = handle_now_time
                >
                </el-date-picker>
            </div></el-col>
            <el-col :span="3" :offset="1"><div class="grid-content bg-purple">
                <el-select v-model="value" clearable placeholder="请选择">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div></el-col>

            <el-col :span="3" :offset="1"><div class="grid-content bg-purple">
                <el-select v-model="value" clearable placeholder="请选择">
                    <el-option
                    v-for="item in options_chongzhiFX"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div></el-col>

            <el-col :span="5" :offset="1"><div class="grid-content bg-purple duizhang_mx">
                <el-input
                    placeholder="请输入内容"
                    prefix-icon="el-icon-search"
                    v-model="input2">
                </el-input>
            </div></el-col>
            </el-row>

            <el-row>
            <el-col :span="6" :offset="1"><div class="grid-content_zhang bg-purple_zhang ">
                <div class="biaoti_name">收入</div>
                <div class="shouru_jine">+700元</div>
                <div class="biaoti_name">共2笔</div>
            </div></el-col>
            <el-col :span="6" :offset="2"><div class="grid-content_zhang bg-purple_zhang ">
                <div class="biaoti_name">支出</div>
                <div class="zhichu_jine">-100元</div>
                <div class="biaoti_name">共2笔</div>
            </div></el-col>
            <el-col :span="6" :offset="2"><div class="grid-content_zhang bg-purple_zhang ">
                <div class="biaoti_name">余额</div>
                <div class="yue_jne">600元</div>

            </div></el-col>
            </el-row>
        </div>

   </div>

   <div class="table_box">
    <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        @selection-change="handleSelectionChange">

        <el-table-column
        type="selection"
        width="100">
        </el-table-column>

        <el-table-column
        label="支付时间"
        width="140">
        <template slot-scope="scope">{{ scope.row.zhifushijian }}</template>
        </el-table-column>

        <el-table-column
        prop="mendian"
        label="门店"
        width="140">
        </el-table-column>

        <el-table-column
        prop="zhifufangshi"
        label="支付方式"
        width="140">
        </el-table-column>

        <el-table-column
        prop="zhifubianhao"
        label="支付编号"
        width="140">
        </el-table-column>

        <el-table-column
        prop="dingdanbianhao"
        label="订单编号"
        width="240">
        </el-table-column>

        <el-table-column
        prop="dingdanjine"
        label="订单金额"
        width="140">
        </el-table-column>

        <el-table-column
        prop="fuwufei"
        label="服务费"
        width="140">
        </el-table-column>

        <el-table-column
        prop="shijijine"
        label="实际金额"
        width="140">
        </el-table-column>


        <el-table-column
        prop="shifoutixian"
        label="是否提现"
        width="120">
        </el-table-column>

        <el-table-column
        prop="tixianshijian"
        label="提现时间"
        width="160">
        </el-table-column>

        <el-table-column
        prop="fuwulieixing"
        label="服务类型"
        width="120"
        show-overflow-tooltip>
        </el-table-column>
    </el-table>

    <div class="xuanxiang_btn">
        <el-row>
            <el-col :span="2" :offset="5"><div class="grid-content bg-purple GN_box">  <div class="GN_btn">自动对账</div> </div></el-col>
            <el-col :span="2" :offset="8"><div class="grid-content bg-purple GN_box"> <div class="GN_btn">导出PDF</div> </div></el-col>
        </el-row>
    </div>
   </div>
</div>

</template>

<script>
export default {
  data() {
    return {
      range_time: '', // 日期选择
      options: [{
        value: '选项1',
        label: '全部门店'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],

      options_chongzhiFX: [{
        value: '选项1',
        label: '充值方式'
      }, {
        value: '选项2',
        label: '双皮奶'
      }],

      value: '选项1',
      input2: '',
      tableData: [{
        zhifushijian:'2016-05-02时分秒',
        mendian:'6-3门店',
        zhifufangshi:'微信',
        zhifubianhao:'1212121212',
        dingdanbianhao:'587997878877878',
        dingdanjine:'50',
        fuwufei:'0.5',
        shijijine:'45.5',
        shifoutixian:'是',
        tixianshijian:'3',
        fuwulieixing:'c端',
      }, {
        zhifushijian:'2016-05-02',
        mendian:'6-3门店',
        zhifufangshi:'微信',
        zhifubianhao:'1212121212',
        dingdanbianhao:'587997878877878',
        dingdanjine:'50',
        fuwufei:'0.5',
        shijijine:'45.5',
        shifoutixian:'是',
        tixianshijian:'3',
        fuwulieixing:'b端',
      }, {
        zhifushijian:'2016-05-02',
        mendian:'6-3门店',
        zhifufangshi:'微信',
        zhifubianhao:'1212121212',
        dingdanbianhao:'587997878877878',
        dingdanjine:'50',
        fuwufei:'0.5',
        shijijine:'45.5',
        shifoutixian:'是',
        tixianshijian:'3',
        fuwulieixing:'充值',
      },]
    }
  },

  methods: {
    handle_now_time(time_val) {
      console.log(time_val)
      let datestart = time_val[0]
      let dateend = time_val[1]
      console.log(this.format(datestart), this.format(dateend))
    },

    add0(m) { return m < 10 ? '0' + m : m },

    format(shijianchuo) {
      // shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo)
      var y = time.getFullYear()
      var m = time.getMonth() + 1
      var d = time.getDate()
      //   var h = time.getHours()
      //   var mm = time.getMinutes()
      //   var s = time.getSeconds()
      return y + '-' + this.add0(m) + '-' + this.add0(d) // +' '+add0(h)+':'+add0(mm)+':'+add0(s);
    }

  }
}
</script>

<style lang="scss">
.duizhang_mx{
    .el-input{
      .el-input__inner{
          border-radius: 20px;
      }
    }
}
</style>

<style lang="scss" scoped>
.el_main{
    width: 100%;
    height: 100%;
    background-color: #f2f6fc;
    overflow: hidden;
}
.xuanxiang{
    width: 96%;
    height: 260px;
    // border: 1px  solid ;
    font-size: 14px;
    background-color: #fff;
    margin: 10px auto;
    border-radius: 6px;
}

.table_box{
    width: 96%;
    height: 460px;
    border-radius: 6px;
    margin: 10px auto 0 auto;
    overflow: hidden;
    background-color: #fff;
    position: relative;
}
.xuanxiang_btn{
    width: 100%;
    height: 60px;
    position: absolute;
    left: 0;
    bottom: 0;
}
.GN_box{
    display: flex;
    justify-content: center;
    align-items: center;
}
.GN_btn{
    width: 100%;
    height: 36px;
    border-radius: 4px;
    background-color: #E53325;
    color: #fff;
    font-size: 14px;
    text-align: center;
    line-height: 36px;
}
// ----
  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    // background: #d3dce6;
    background-color: #fff;
  }
  .bg-purple-light {
    // background: #e5e9f2;
    background-color: #fff;
  }
  .grid-content {
    border-radius: 4px;
    height: 50px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
// ----
  .grid-content_zhang{
    border-radius: 4px;
    height: 150px;
  }
  .bg-purple_zhang{
    // background: #d3dce6;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .biaoti_name{
    width: 100%;
    text-align: center;
    font-size: 16px;
  }
  .shouru_jine{
    width: 100%;
    text-align: center;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    font-weight: bold;
    color: #DE3B2C;
  }
  .zhichu_jine{
    width: 100%;
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-size: 20px;
    font-weight: bold;
    color: #99D08B;
  }
  .yue_jne{
    width: 100%;
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-size: 20px;
    font-weight: bold;
    color: #333;
  }

</style>